import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const playSound = () => {
  const clickSound = new Audio('/click.mp3');
  clickSound.play();
};

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo">
          <img src="/oinklogo.png" alt="Logo" />
        </div>
        <ul>
          <li><Link to="/" onClick={playSound}>Home</Link></li>
          <li><Link to="/oinkdata" onClick={playSound}>$OINK Data</Link></li>
          <li><Link to="/chart" onClick={playSound}>Chart</Link></li>
          <li><Link to="/governance" onClick={playSound}>Governance</Link></li>
          <li><Link to="/portfolio" onClick={playSound}>Portfolio</Link></li>  
          <li><Link to="/games" onClick={playSound}>Games</Link></li>  
        </ul>
      </nav>
      <div className="navbar2">
        <div className="navbar2-logo2">
          <a href="/"> <img src="/oinklogo.png" alt="$OINK Logo" className="logo" /> </a>
        </div>
        <div className={`navbar2-links2 ${menuOpen ? 'open' : ''}`}>
          <ul>
            <h1 className="linkstitle">$OINK NAVIGATION</h1>
            <h2><Link to="/" onClick={playSound}>Home</Link></h2>
            <h2><Link to="/oinkdata" onClick={playSound}>$OINK Data</Link></h2>
            <h2><Link to="/chart" onClick={playSound}>Chart</Link></h2>
            <li><Link to="/governance" onClick={playSound}>Governance</Link></li>
            <h2><Link to="/portfolio" onClick={playSound}>Portfolio</Link></h2>
            <h2><Link to="/games" onClick={playSound}>Games</Link></h2>
          </ul>
        </div>
        <div className="menu-toggle2" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar2"></span>
          <span className="bar3"></span>
        </div>
      </div>
    </>
  );
};

export default Navbar;
