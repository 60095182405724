import React, { useState, useEffect } from 'react';
import './Home.css';
import Navbar from './Navbar';
import Contact from './Contact';

const Home: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(() => {
        // Check local storage to determine if the video should play
        return !localStorage.getItem('videoPlayed');
    });

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const [isIntroBoxVisible, setIsIntroBoxVisible] = useState(false);
    const [isSuperTextBoxVisible, setIsSuperTextBoxVisible] = useState(false);
    const [isTextSectionVisible, setIsTextSectionVisible] = useState(false);
    const toggleIntroBox = () => setIsIntroBoxVisible(!isIntroBoxVisible);
    const toggleSuperTextBox = () => setIsSuperTextBoxVisible(!isSuperTextBoxVisible);
    const toggleTextSection = () => setIsTextSectionVisible(!isTextSectionVisible);
    

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsVideoPlaying(false);
            }
        };

        handleResize(); // Check the screen size on component mount

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isVideoPlaying) {
            const timer = setTimeout(() => {
                setIsVideoPlaying(false);
                localStorage.setItem('videoPlayed', 'true'); // Set flag in local storage
            }, 5000); // Duration of the video in milliseconds

            return () => clearTimeout(timer);
        }
    }, [isVideoPlaying]);

    return (
        <>
            {/* Handle Splash Video */}
            {isVideoPlaying && (
                <div className="video-splash">
                    <video className="video" autoPlay muted playsInline>
                        <source src="/oinkwelcome.mp4" type="video/mp4" />
                    </video>
                </div>
            )}
            {!isVideoPlaying && (
                <>
                    <Navbar />

                    
                        {/* MEET OINK */}
                        <div className="homewrapper">
                            <div className="intro-page">
                                {/* Image Section */}
                                <div className="image-section">
                                    {/* Video */}
                                    <h3 className="text-header">MEET $OINK!</h3>
                                    <div className="video-box">
                                       <img className='superpig1' src="superpig.gif" alt="" />
                                    </div>
                                    {/* Links */}
                                    <div className="links-section2">
                                        <a href="https://jup.ag/swap/SOL-2krSmvPUDVwzpPTCqV54DyxbpZak36czjcaCtyLpqMte" className="link-item2">Swap</a>
                                        <a href="https://t.me/oinksolportal" className="link-item2">Telegram</a>
                                        <a href="https://x.com/OINKtheSOLpig" className="link-item2">Twitter</a>
                                        <a href="/Donate" className="link-item2">Donate</a>
                                    </div>
                                </div>
                                {/* Text Section */}
                                <button className="toggle-button2" onClick={toggleIntroBox}>
                                    Click me for origin, team, and growth info! 
                                </button>
                                <div className={`intro-section ${isIntroBoxVisible ? 'visible-content' : 'hidden-content'}`}>
                                    <div className="intro-box">
                                        <h3>Unique Start & Team</h3>
                                        <p className="oinktext">
                                            April 5th, 2024 was the day this pig got out of the mud. The OG dev contacted the current community team to takeover $OINK. The team secured the Dexscreener and moved the community over to a new TG. The past dev then even gave us the dev wallet, which we used to update the token Logo and then make the metadata immuteable! The team now consists of community members with diverse backgrounds, including over 7 years in crypto, animation production experience, and extensive blockchain development experience. Our entire community is our team, as a true CTO should be!
                                        </p>
                                    </div>
                                    <div className="intro-box">
                                        <h3>Brand Growth & Future</h3>
                                        <p className="oinktext">
                                            We've funded a marketing wallet, visible on SolScan under OinkMarketing.Sol, to support marketing efforts. We've already met some of our roadmap goals and will be adding even more as we progress! The addition of roadmap items and a shift towards a more efficiently operated token is apart of our revamp 2.0 that started on July 1st, 2024. We're looking forward to what the future holds and building together as a community!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ROADMAP */}
                        <div className="homewrapper">
                            <div className="home-page">

                                {/* Text Section */}
                                <div className="supertext">
                                    <div className="supertextbox">
                                        <h3>$OINK Roadmap & Utility</h3>
                                        <p className="superoink">
                                            -CTO on April 5th, 2024<span className="checkmark">&#10004;</span>
                                        </p>
                                        <p className="superoink">
                                            -Create new website, Telegram, and X account. Update DexScreener and other socials.<span className="checkmark">&#10004;</span>
                                        </p>
                                        <p className="superoink">
                                            -Launch games section on website. Continue to add more content over time and enable web3 features. (Game #1 added.)<span className="checkmark">&#10004;</span>
                                        </p>
                                        <p className="superoink">
                                            -Marketing through KOL's, Influencers, and project partners. (In Progress)
                                        </p>
                                        <p className="superoink">
                                            -Develop web3 dAPPs on our site for $OINK holders. (In progress. Portfolio and Crypto Data section are live. Trading section, governance platform, and other programs are on the way)
                                        </p>
                                        <p className="superoink">
                                            -License out created dAPPs to other projects.
                                        </p>
                                        <p className="superoink">
                                            -More items on the way as we undergo revamp 2.0 as of July 1st, 2024!
                                        </p>
                                        <p className="superoink">
                                            -Disclaimer: The goals above are subject to change as the community sees fit. $OINK is a community ran memecoin at heart and should not be treated as an investment vehicle.
                                        </p>
                                    </div>
                                </div>
                                {/* Image Section */}
                                
                            </div>
                        </div>
                        {/* Card 2 Media */}
                        
                        {/* Card 3 Contact */}
                        <div>
                            <Contact />
                        </div>
                        <div className="homewrapper">
                            <img className="skater" src="/skater2.gif" alt="skater" />
                        </div>
                        <div className="ender">
                           <p className="superoink3">Last Updated: July 2nd, 2024</p>
                        </div>
                        
                    

                </>
            )}
        </>
    );
};

export default Home;