import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import Navbar from './Navbar';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    telegram: '',
    message: '',
    twitter: '',
    website: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Sending form data:', formData);

    emailjs.send('service_16z9syz', 'template_oeajf6e', formData, 'OmzPIYcgkG2xZH0U1')
      .then((result) => {
        console.log('Email sent:', result.text);
        alert('Message sent! We will respond soon!');
      }, (error) => {
        console.error('Email send error:', error.text);
        alert('Failed to send message. Please try again later.');
      });

    setFormData({
      email: '',
      telegram: '',
      message: '',
      twitter: '',
      website: ''
    });
  };

  return (
    <>
    <div className="homewrapper1">
    <div className="contpage">
    <div className="contact-page">
      <h2>Contact $OINK!</h2>
      <h3>Fill out the applicable fields below and we'll get back to you asap! Please indicate if this is for a YouTube review, AMA, SpaceCast, or other. </h3>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label>Email (Optional)</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Telegram (Optional)</label>
          <input
            type="text"
            name="telegram"
            value={formData.telegram}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Twitter (Optional)</label>
          <input
            type="text"
            name="twitter"
            value={formData.twitter}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Website (Optional)</label>
          <input
            type="text"
            name="website"
            value={formData.twitter}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Message (Required)</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
    </div>
    </div>
    </>
  );
}

export default Contact;