import React, { useState } from 'react';
import { Connection, PublicKey, LAMPORTS_PER_SOL, Transaction, SystemProgram } from '@solana/web3.js';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import './DonationButton.css';

const SOLANA_RPC_ENDPOINT = 'https://intensive-delicate-road.solana-mainnet.quiknode.pro/1766608e3638d14189d9863e13ff5d39abf41763/';

const DonationButton: React.FC = () => {
    const { publicKey, sendTransaction } = useWallet();
    const [amount, setAmount] = useState<number>(0);
    const [message, setMessage] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [transactionSignature, setTransactionSignature] = useState<string | null>(null);
    const marketingWallet = new PublicKey('5hRCRYaJQjjrhNarY7yWrjRCcyVwhW6VJQd2vev5Ey5j');

    const handleDonate = async () => {
        if (!publicKey) {
            setMessage('Please connect your wallet');
            return;
        }

        const connection = new Connection(SOLANA_RPC_ENDPOINT, 'confirmed');
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: marketingWallet,
                lamports: amount * LAMPORTS_PER_SOL,
            })
        );

        try {
            const signature = await sendTransaction(transaction, connection);
            setTransactionSignature(signature);
            setMessage(`Donation successful! Transaction signature: ${signature}`);
            setShowModal(true);
        } catch (error) {
            console.error('Transaction failed', error);
            setMessage('Donation failed. Please try again.');
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setTransactionSignature(null);
    };

    return (
        <div className="donation-button-container">
            <WalletMultiButton />
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
                placeholder="Enter amount in SOL"
            />
            <button onClick={handleDonate}>Donate</button>
            {message && <p>{message}</p>}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h1>Donation Successful!</h1>
                        <h1>Thank You!</h1>
                        <h4><strong>Transaction signature:</strong> {transactionSignature}</h4>
                        {transactionSignature && (
                            <a href={`https://solscan.io/tx/${transactionSignature}`} className='txlink' target="_blank" rel="noopener noreferrer">
                                Transaction Link
                            </a>
                        )}
                        <button onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DonationButton;