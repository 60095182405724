import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import './GovNav.css';


const playSound = () => {
  const clickSound = new Audio('/click.mp3');
  clickSound.play();
};

const GovNav: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>    
    <nav className="gov-nav">
      <Link to="/" onClick={playSound}>$OINK Home</Link>
      <Link to="/governance"onClick={playSound}>Governance</Link>
      <Link to="/submit"onClick={playSound}>Submit a Proposal</Link>
      <Link to="/live"onClick={playSound}>View Live Proposals</Link>
      <Link to="/finalized"onClick={playSound}>Finalized Proposals</Link>
      <WalletMultiButton />
    </nav>
    <div className="gov-nav2">
    <WalletMultiButton />
    <div className={`navbar2-links2 ${menuOpen ? 'open' : ''}`}>
      <ul>
        <h1 className="linkstitle">$OINK NAVIGATION</h1>
        <h2><Link to="/" onClick={playSound}>$OINK Home</Link></h2>
        <h2><Link to="/governance" onClick={playSound}>Governance Home</Link></h2>
        <h2><Link to="/submit" onClick={playSound}>Submit Proposal</Link></h2>
        <li><Link to="/live" onClick={playSound}>Live Proposals</Link></li>
        <h2><Link to="/finalized" onClick={playSound}>Finalized Proposals</Link></h2>
      </ul>
    </div>
    <div className="menu-toggle2" onClick={toggleMenu}>
      <span className="bar"></span>
      <span className="bar2"></span>
      <span className="bar3"></span>
      <span className="bar4"></span>
    </div>
  </div>
  </>

  );
};

export default GovNav;