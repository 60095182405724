import React, { useEffect, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import axios from 'axios';
import './Portfolio.css';
import Navbar from './Navbar';

const SOLANA_RPC_ENDPOINT = 'https://intensive-delicate-road.solana-mainnet.quiknode.pro/1766608e3638d14189d9863e13ff5d39abf41763/';
const TOKEN_MINT_ADDRESS = '2krSmvPUDVwzpPTCqV54DyxbpZak36czjcaCtyLpqMte';
const DEX_SCREENER_API_URL = 'https://api.dexscreener.com/latest/dex/tokens/';

const Portfolio: React.FC = () => {
    const wallet = useWallet();
    const [balance, setBalance] = useState<number | null>(null);
    const [tokenBalance, setTokenBalance] = useState<number | null>(null);
    const [totalSupply, ] = useState<number>(1000000000);
    const [tokenPrice, setTokenPrice] = useState<number | null>(null);

    useEffect(() => {
        const connection = new Connection(SOLANA_RPC_ENDPOINT);

        const fetchBalance = async () => {
            if (wallet.publicKey) {
                try {
                    const balance = await connection.getBalance(wallet.publicKey);
                    console.log('Fetched SOL balance:', balance);
                    setBalance(balance / 1e9);
                } catch (error) {
                    console.error('Failed to get balance:', error);
                }
            }
        };

        const fetchTokenData = async () => {
            if (wallet.publicKey) {
                try {
                    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {
                        mint: new PublicKey(TOKEN_MINT_ADDRESS)
                    });

                    if (tokenAccounts.value.length > 0) {
                        const tokenAmount = tokenAccounts.value[0].account.data.parsed.info.tokenAmount.uiAmount;
                        console.log('Fetched token balance:', tokenAmount);
                        setTokenBalance(tokenAmount);
                    } else {
                        console.log('No token accounts found for this wallet');
                        setTokenBalance(0);
                    }

                    const response = await axios.get(`${DEX_SCREENER_API_URL}${TOKEN_MINT_ADDRESS}`);
                    const price = response.data.pairs[0].priceUsd;
                    console.log('Fetched token price:', price);
                    setTokenPrice(price);
                } catch (error) {
                    console.error('Failed to get token data:', error);
                    setTokenBalance(0);
                }
            }
        };

        fetchBalance();
        fetchTokenData();
    }, [wallet.publicKey]);

    const percentageOfSupply = tokenBalance ? (tokenBalance / totalSupply) * 1000 : 0;
    const tokenValueInUSD = tokenBalance && tokenPrice ? tokenBalance * tokenPrice : 0;

    return (
        <>
        <Navbar />
        <div className="portpage1">
        <div className="portfolio-page">
            <h1>$OINK-FOLIO!</h1>
            <h3>CONNECT TO VIEW YOUR $OINK STATS!</h3>
            <WalletMultiButton />
            {wallet.connected && (
                <div className="portfolio-details">
                    <p><strong>SOL Balance ___</strong> {balance !== null ? balance.toFixed(4) : 'Loading...'} SOL </p>
                    <p><strong>Token Balance ___</strong> {tokenBalance !== null ? (tokenBalance > 0 ? tokenBalance.toFixed(2) : '0') : 'Loading...'} $OINK</p>
                    <p><strong>Percentage of Total Supply ___</strong> {tokenBalance ? percentageOfSupply.toFixed(4) : 0}%</p>
                    <p><strong>Current Estimated USD Value ___</strong> ${tokenBalance ? tokenValueInUSD.toFixed(2) : 0} USD</p>
                </div>
            )}
        </div>
        </div>
        </>
    );
};

export default Portfolio;
