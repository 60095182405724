import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    LedgerWalletAdapter,
    TorusWalletAdapter,
    BitpieWalletAdapter,
    MathWalletAdapter,
    CloverWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Chart from './pages/Chart';
import './App.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import OINKData from './pages/OinkData';
import Donate from './pages/Donate';
import GameIframe from './components/GameIframe';
import Games from './pages/Games';
import Governance from './pages/Governance';
import ProposalSubmission from './pages/ProposalSubmission';
import ProposalList from './pages/ProposalList';
import FinalizedProposals from './pages/FinalizedProposals';
import { useState } from 'react';


const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    console.log("Admin logged in");
  };
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = 'https://api.mainnet-beta.solana.com';
  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter({ network }),
    new LedgerWalletAdapter(),
    new TorusWalletAdapter(),
    new BitpieWalletAdapter(),
    new MathWalletAdapter(),
    new CloverWalletAdapter(),
  ];

  return (
    <Router>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <div className="App">
           
              <div className="main-content">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/chart" element={<Chart />} />
                  <Route path="/oinkdata" element={<OINKData />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/donate" element={<Donate />} />
                  <Route path="/flappyoink" element={<GameIframe />} />
                  <Route path="/games" element={<Games />} />
                  <Route path="/governance" element={<Governance />} />
                  <Route path="/submit" element={<ProposalSubmission />} />
                  <Route path="/live" element={<ProposalList />} />
                  <Route path="/finalized" element={<FinalizedProposals />} />
                  
                  
                </Routes>
                <div className="wallet-button">
                 
                </div>
              </div>
            </div>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Router>
  );
};

export default App;
