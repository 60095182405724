import React from 'react';

const GameIframe: React.FC = () => {
    return (
        <>
        <div style={{ textAlign: 'center' }}>
            <iframe
                src="/flappyoink/index.html"
                title="Flappy $OINK"
                width="100vw"
                height="100vh"
                style={{ border: 'none' }}
            ></iframe>
        </div>
        </>
    );
};

export default GameIframe;