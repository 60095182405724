import React from 'react';
import GovNav from './GovNav';
import './Governance.css';

const Governance: React.FC = () => {
  return (
    <div>
      <GovNav />
      <div className="govwrapper">
      <div className="governance-page">
        <h2>$OINK Governance</h2>
        <p>Welcome to the governance portal! Please use the navigation above to submit proposals, view live proposals, or see finalized proposals.</p>
         <p>(500,000 $OINK required to submit and vote on proposals. Subject to change)</p> 
         <p>(All proposals are live for two weeks after submission)</p> 
        <div className="guidelines-section">
          <h2>Proposal Guidelines</h2>
          <ol>
            <li>
              <strong>Compliance with Laws and Regulations:</strong>
              <ul>
                <li>Proposals must comply with all applicable laws and regulations. Proposals that seek to provide dividends or any form of financial return to token holders will be automatically rejected.</li>
              </ul>
            </li>
            <li>
              <strong>Relevance to the Project:</strong>
              <ul>
                <li>Proposals must be relevant to the project’s mission and goals. Irrelevant proposals will not be considered.</li>
              </ul>
            </li>
            <li>
              <strong>Clarity and Specificity:</strong>
              <ul>
                <li>Proposals should be clear, specific, and actionable. They should provide a detailed description of the issue, the proposed solution, and the expected impact.</li>
              </ul>
            </li>
            <li>
              <strong>Respectful and Non-Discriminatory:</strong>
              <ul>
                <li>Proposals must be respectful and free from discriminatory, hateful, or offensive content. Proposals that contain such content will be rejected.</li>
              </ul>
            </li>
            <li>
              <strong>No Self-Promotion or Advertising:</strong>
              <ul>
                <li>Proposals must not be used for self-promotion or advertising of external products or services. Such proposals will be rejected.</li>
              </ul>
            </li>
            <li>
              <strong>Budget and Resource Considerations:</strong>
              <ul>
                <li>Proposals should include a realistic assessment of the required budget and resources. Proposals that lack feasibility in terms of budget or resources may be rejected.</li>
              </ul>
            </li>
            <li>
              <strong>Single Issue Focus:</strong>
              <ul>
                <li>Each proposal should focus on a single issue or topic. Combining multiple issues into one proposal is discouraged and may lead to rejection.</li>
              </ul>
            </li>
            <li>
              <strong>Community Benefit:</strong>
              <ul>
                <li>Proposals should aim to benefit the entire community or project. Proposals that serve only a small group or individual interests will be scrutinized closely.</li>
              </ul>
            </li>
            <li>
              <strong>Transparency and Accountability:</strong>
              <ul>
                <li>Proposals should include mechanisms for transparency and accountability. This may include regular updates, reporting, or measurable milestones.</li>
              </ul>
            </li>
            <li>
              <strong>Respect for Previous Decisions:</strong>
              <ul>
                <li>Proposals that seek to overturn or significantly alter previous community decisions should provide strong justification and evidence of widespread community support.</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Governance;