import React, { useEffect, useState } from 'react';
import { Connection, PublicKey, ParsedAccountData } from '@solana/web3.js';
import axios from 'axios';
import './OINKData.css';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import dotenv from 'dotenv';


const SOLANA_RPC_ENDPOINT = 'https://intensive-delicate-road.solana-mainnet.quiknode.pro/1766608e3638d14189d9863e13ff5d39abf41763/';
const TOKEN_MINT_ADDRESS = '2krSmvPUDVwzpPTCqV54DyxbpZak36czjcaCtyLpqMte';
const DEX_SCREENER_API_URL = 'https://api.dexscreener.com/latest/dex/tokens/';

const OINKData: React.FC = () => {
  const [circulatingSupply, setCirculatingSupply] = useState<number | null>(null);
  const [holderCount, setHolderCount] = useState<number | null>(null);
  const [tokenPriceUSD, setTokenPriceUSD] = useState<number | null>(null);
  const [tokenPriceSOL, setTokenPriceSOL] = useState<number | null>(null);
  const [marketCap, setMarketCap] = useState<number | null>(null);

  useEffect(() => {
    const connection = new Connection(SOLANA_RPC_ENDPOINT);

    const fetchTokenData = async () => {
      try {
        // Fetch token accounts data
        const tokenAccounts = await connection.getParsedProgramAccounts(
          new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"), // Token program ID
          {
            filters: [
              {
                dataSize: 165, // Token account size
              },
              {
                memcmp: {
                  offset: 0,
                  bytes: TOKEN_MINT_ADDRESS,
                },
              },
            ],
          }
        );

        const nonZeroAccounts = tokenAccounts.filter(account => {
          const parsedData = account.account.data as ParsedAccountData;
          const tokenAmount = parsedData.parsed.info.tokenAmount.uiAmount;
          return tokenAmount > 0;
        });

        const circulatingSupply = nonZeroAccounts.reduce((acc, account) => {
          const parsedData = account.account.data as ParsedAccountData;
          const tokenAmount = parsedData.parsed.info.tokenAmount.uiAmount;
          return acc + tokenAmount;
        }, 0);

        const holderCount = nonZeroAccounts.length;

        setCirculatingSupply(circulatingSupply);
        setHolderCount(holderCount);

        // Fetch token price from Dexscreener
        const response = await axios.get(`${DEX_SCREENER_API_URL}${TOKEN_MINT_ADDRESS}`);
        const priceUSD = parseFloat(response.data.pairs[0].priceUsd);
        const priceSOL = parseFloat(response.data.pairs[0].priceNative); // Assuming this returns the price in SOL

        setTokenPriceUSD(priceUSD);
        setTokenPriceSOL(priceSOL);

        const marketCap = Math.floor(circulatingSupply * priceUSD);
        setMarketCap(marketCap);

      } catch (error) {
        console.error('Failed to get token data:', error);
      }
    };

    fetchTokenData();
  }, []);

  const maxSupply = 100000000;
  const burnedTokens = circulatingSupply !== null ? maxSupply - circulatingSupply : 'Loading...';

  return (
    <>
      <Navbar />
      <div className="datawrapper">
      <div className="oink-data-page">
        <div className="oink-data-container">
          <div className="data-box">
            <h3>Token Symbol</h3>
            <p>OINK</p>
          </div>
          <div className="data-box">
            <h3>Contract Owner</h3>
            <p>Renounced</p>
          </div>
          <div className="data-box">
            <h3>Solscan Link</h3>
            <a href="https://solscan.io/token/2krSmvPUDVwzpPTCqV54DyxbpZak36czjcaCtyLpqMte" className="link-item4">Click Here</a>
          </div>
          <div className="data-box">
            <h3>LP Burn Tx</h3>
            <a href="https://solscan.io/tx/4Ayh8JAqRkWN4eRMj1MWx75M9NkQKJpA6PLv2qY93AaTwidBhBmdxkKkN7zb3f7mVxn9iDg5Dqaev5H8SiQoW4qv" className="link-item4">Click Here</a>
          </div>
          <div className="data-box">
            <h3>Metadata Type</h3>
            <p>Immutable</p>
          </div>
          <div className="data-box">
            <h3>Max Supply</h3>
            <p>{maxSupply.toLocaleString()}</p>
          </div>
          <div className="data-box">
            <h3>Circulating Supply</h3>
            <p>{circulatingSupply !== null ? circulatingSupply.toLocaleString() : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>$OINK Burned</h3>
            <p>{burnedTokens.toLocaleString()}</p>
          </div>
          <div className="data-box">
            <h3>Current Price in SOL</h3>
            <p>{tokenPriceSOL !== null ? tokenPriceSOL.toFixed(9) : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>Current Price in USD</h3>
            <p>${tokenPriceUSD !== null ? tokenPriceUSD.toFixed(7) : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>Market Cap</h3>
            <p>${marketCap !== null ? marketCap.toLocaleString() : 'Loading...'}</p>
          </div>
          <div className="data-box">
            <h3>Holder Count</h3>
            <p>{holderCount !== null ? holderCount.toLocaleString() : 'Loading...'}</p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default OINKData;