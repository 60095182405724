import React, { useState, useEffect } from 'react';
import './Home.css';
import Navbar from './Navbar';
import DonationButton from '../components/DonationButton';

const Donation: React.FC = () => {
  return (
    <>
    <Navbar/>
    <div className="another-page">
            <h1>Support $OINK!</h1>
            <h2>All funds go to our multi-signature wallet, OinkMarketing.sol</h2>
            <h3>For amounts under 1 SOL, please input 0 first. Example: 0.25</h3>
            <h3>A confirmation message along with your transaction signature and link will pop up after the donation is complete.</h3>
            <h5>Thank You!</h5>
            <DonationButton />
        </div>
    </>
  )
  }

export default Donation;