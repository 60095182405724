import React, { useState } from 'react';
import axios from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import GovNav from './GovNav';
import Modal from './Modal';
import './ProposalSubmission.css';

const ProposalSubmission: React.FC = () => {
  const wallet = useWallet();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!wallet.publicKey) {
      setMessage('Please Connect Your Wallet');
      setShowModal(true);
      return;
    }

    try {
      const walletAddress = wallet.publicKey.toBase58();
      console.log('Submitting proposal:', { title, description, walletAddress });
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/proposals`, {
        title,
        description,
        walletAddress,
      });
      console.log('Response:', response.data);
      setMessage('Proposal Submitted Successfully!');
      setTitle('');
      setDescription('');
    } catch (error: any) {
      console.error('Error submitting proposal:', error);
      setMessage(error.response?.data || 'An error occurred');
    } finally {
      setShowModal(true);
    }
  };

  return (
    <div>
      <GovNav />
      <div className="subwrapper">
        <div className="proposal-submission">
          <h2>Submit a Proposal</h2>
          <p>Must hold 500k $OINK to submit. No charge when submitting!</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Proposal Title"
              required
            />
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Proposal Description"
              required
            />
            <button type="submit">Submit Proposal</button>
          </form>
        </div>
        {showModal && <Modal message={message} onClose={() => setShowModal(false)} />}
      </div>
    </div>
  );
};

export default ProposalSubmission;