import React from 'react';
import './Chart.css';
import { useState } from 'react';
import Navbar from './Navbar';

const Chart: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
    <Navbar/>
    <div className="chart-page">
      <div id="dexscreener-embed">
        <iframe 
          src="https://dexscreener.com/solana/FowRoFtSeDaSxPksEXAzAfzuYdeL6EbJHaJn1ycjNCAr?embed=1&theme=dark&trades=0" 
          title="Dexscreener Chart"
          allowFullScreen
        ></iframe>
      </div>
    </div>
    <div className="chart-page2">
      <div id="dexscreener-embed">
        <iframe 
          src="https://dexscreener.com/solana/FowRoFtSeDaSxPksEXAzAfzuYdeL6EbJHaJn1ycjNCAr?embed=1&theme=dark&trades=0" 
          title="Dexscreener Chart"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  </>
  );
};

export default Chart;
