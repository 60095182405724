import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import Navbar from './Navbar';


const Games: React.FC = () => {

  return (
    <>
          <Navbar />
         <div className="homewrapper2">
          <div className="home-page">
            <div className="text-section2">
            <h2 className="text-header">GAMES</h2>
              <div className="text-box3">
                <h3>Flappy $OINK!</h3>
                <img className="gamepreview" src="/flappyoink.png" alt="FlaappyOink" />
                <a href="/flappyoink" className="link-item5">Play!</a>
              </div>
            </div>
          </div>
          </div>
        </>
  );
};

export default Games;
